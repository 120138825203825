// @Function: Equalize blocks
// How to use:
// Add a data-eq attribute on every blocks you want to equalize
// Example: <div data-eq="blocks-red"></div>
// Each block with data-eq="blocks-red" will have the same height
// On resize, the height is reset so there is no conflict with the previous height
(function ($) {
    var iframeLoad = 0;

    // Set unique values for the array "ar"
    function uniqueArray(ar) {
        var j = {};

        ar.forEach(function (v) {
            j[v + '::' + typeof v] = v;
        });

        return Object.keys(j).map(function (v) {
            return j[v];
        });
    }

    // Equalize blocks height by setting the same min-height value
    function equalizeBlocks() {
        var blockArray = $('[data-eq]').map(function () {
            return $(this).data('eq');
        }).get();
        var blocks = uniqueArray(blockArray);

        blocks.forEach(function (elem) {
            var prev = 0;
            $('[data-eq="' + elem + '"]')
                .css('min-height', '')
                .each(function () {
                    if ($(this).outerHeight() > prev) {
                        prev = $(this).outerHeight();
                    }
                })
                .css('min-height', prev + 'px');
        });
    }

    // Square blocks height
    function squareBlocks() {
        $('[data-square]').css('min-height', '');
        $('[data-square]').each(function(ev){
            $(this).css('min-height', $(this).outerWidth());
        });
    }

    function resizeSimpleHeader() {
        var header = $(".header--simple");
        header.css('min-height', '');
        header.css('min-height', $(".header--simple__sticky").outerHeight() + 'px');
    }

    // Equalize on page load
    $(window).on('load', function () {
        squareBlocks();
        equalizeBlocks();
        resizeSimpleHeader();
    });

    // Equalize on page resize
    $(window).resize(function () {
        squareBlocks();
        equalizeBlocks();
        resizeSimpleHeader();
    });

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        var heightChanged = false;
        if (scroll >= 1) {
            $(".header-simple__sticky").addClass("glue");
            if (heightChanged === false) {
                $(".header--simple").css('min-height', $(".header-simple__sticky").outerHeight() + 'px');
                heightChanged = true;
            }
        } else {
            $(".header-simple__sticky").removeClass("glue");
            $(".header-simple").css('min-height', '');
            heightChanged = false;
        }
    });

    if ($('.grid').length > 0) {
        var nbIframe = $('iframe').length;
        $('iframe').each(function() {
            $(this).on('load', function() {
                iframeLoad++;
                if (iframeLoad === nbIframe) {
                    setTimeout(function() {
                        equalizeBlocks();
                        $('.grid').isotope({
                            filter: '*'
                        })
                    }, 1000);
                }
            })
        });
    }

    $('.header-customiz > a, .header-burger__customize-btn').on('click', function(e) {
        e.preventDefault();
        $('.customize-menu').toggleClass('customize-menu--active');
    });

    $('.social-modal-trigger').on('click', function(e) {
        e.preventDefault();
        $('#share-modal').modal("show");
    })

})(jQuery);

// Initialize isotope on .grid class
(function ($) {
    var iframeLoad = 0;

    $(window).on('load', function () {
        $('.grid').isotope({
            percentPosition: true,
            itemSelector: '.grid-item',
            masonry: {
                columnWidth: '.grid-sizer'
            }
        }).css('opacity', 1);

        $('.grid-filter').on('change', function(e) {
            var inclusives = [];

            if ($(this).attr('id') == 'all') {
                if (this.checked) {
                    $('.grid-filter').prop('checked', true);
                }
                else {
                    $('.grid-filter').prop('checked', false);
                }
            }
            else {
                $('#all').prop('checked', false);
            }
            $('.grid-filter').each(function(i, elem) {
                if (elem.checked) {
                    inclusives.push(elem.value);
                }
            });

            var filterValue = inclusives.length ? inclusives.join(', ') : '*';

            $('.grid').isotope({
                filter: filterValue
            })
        });

        setTimeout(function() {
            $('.grid').isotope({
                filter: '*'
            })
        }, 1000);
    });

    // Fix layout if there is iframe in the grid
    if ($('.grid').length > 0) {
        $('iframe').each(function() {
            $(this).on('load', function() {
                iframeLoad++;
                if (iframeLoad === $('iframe').length) {
                    setTimeout(function() {
                        $('.grid').isotope({
                            filter: '*'
                        })
                    }, 1000);
                }
            })
        });
    }
})(jQuery);